import React, { useState, useEffect } from 'react';
import { Button, Input, Dropdown } from 'semantic-ui-react';
// import DatePicker from 'react-date-picker';
// import { CalenarIcon, CancelNoCircleIcon } from '../assets/icon';
import api from '../api';
import intl from 'react-intl-universal';
import { categoryOptions, equalOptions, stateOptions } from './Constant';

export default function PresetMasterSearch({ userList, searchObj, setSearchObj, onFetch, initSearchObj }) {
  const [error, setError] = useState();
  const [fsItemlist, setFsItemlist] = useState([]);
  const [creatorOptions, setCreatorOptions] = useState([]);

  const onChange = (type, value) => {
    setSearchObj({ ...searchObj, [type]: value })
  };

  useEffect(() => {
    getFsList();
  }, [])

  const getFsList = async () => {
    try {
      const { data: { fsItemlist } } = await api.get(`/dppapi/preset/fs-item-list/query`);
      const list = fsItemlist.map((item, index) => ({
        key: index,
        value: item.fs_item,
        text: item.fs_item,
      }))
      setFsItemlist(list);
    } catch (error) {
      setError(error.data.errmsg);
    } 
  }

  useEffect(() => {
    const options = userList.map((item, index) => ({
      key: index,
      value: item.id,
      text: item.name,
    }))

    setCreatorOptions(options);
  }, [userList])

  const handleReset = () => {
    setSearchObj(prev => initSearchObj);
  }

  const handleClickSearchButton = () => {
    onFetch();
  }

  return (
    <React.Fragment>
      <div className="preset-master-search-conditions-panel">
        <div className="preset-master-title">自動連携先の更新検討アイテム一覧</div>
        <div className="preset-master-search-conditions-list">
          <div className="preset-master-search-condition-first-floor">
            <div className="preset-master-search-condition-input">
              <div className="preset-master-input-label">{intl.get('_preset.master.FsItemSearch.Classification')}</div>
              <Dropdown
                fluid
                clearable
                options={categoryOptions || []}
                selection
                search
                className="preset-master-input-content"
                onChange={(e, data) => onChange('category', data.value)}
                noResultsMessage={intl.get('_preset.master.FsItemSearch.Noresults')}
                value={searchObj.category || ""} />
            </div>
            <div className="preset-master-search-condition-input">
              <div className="preset-master-input-label">{intl.get('_preset.master.FsItemSearch.Title')}</div>
              <Input
                fluid
                className="preset-master-input-content"
                onChange={e => onChange('subTitle', e.target.value)}
                value={searchObj.subTitle || ""}
              />
            </div>
            <div className="preset-master-search-condition-input">
              <div className="preset-master-input-label">{intl.get('_preset.master.FsItemSearch.SubTitle')}</div>
              <Input
                fluid
                className="preset-master-input-content"
                onChange={e => onChange('item', e.target.value)}
                value={searchObj.item || ""}
              />
            </div>
            <div className="preset-master-search-condition-input">
              <div className="preset-master-input-label">{intl.get('_preset.master.FsItemSearch.ScrumItem')}（{intl.get('_preset.master.FsItemSearch.Now')}）</div>
              <Dropdown
                fluid
                clearable
                options={fsItemlist || []}
                selection
                search
                className="preset-master-input-content"
                onChange={(e, data) => onChange('originalItem', data.value)}
                noResultsMessage={error || intl.get('_preset.master.FsItemSearch.Noresults')}
                value={searchObj.originalItem || ""} />
            </div>
            <div className="preset-master-search-condition-input">
              <div className="preset-master-input-label">{intl.get('_preset.master.FsItemSearch.ScrumItem')}（{intl.get('_preset.master.FsItemSearch.New')}）</div>
              <Dropdown
                fluid
                clearable
                options={fsItemlist || []}
                selection
                search
                className="preset-master-input-content"
                onChange={(e, data) => onChange('scrumItem', data.value)}
                noResultsMessage={error || intl.get('_preset.master.FsItemSearch.Noresults')}
                value={searchObj.scrumItem || ""} />
            </div>
          </div>
          <div className="preset-master-search-condition">
            <div className="preset-master-search-condition-input">
              <div className="preset-master-input-label">{intl.get('_preset.master.FsItemSearch.Now')}/{intl.get('_preset.master.FsItemSearch.New')}</div>
              <Dropdown
                fluid
                clearable
                options={equalOptions || []}
                selection
                search
                className="preset-master-input-content"
                onChange={(e, data) => onChange('isEqual', data.value)}
                noResultsMessage={intl.get('_predict.admin.PdfSearch.Noresults')}
                value={searchObj.isEqual} />
            </div>
            <div className="preset-master-search-condition-input">
              <div className="preset-master-input-label">{intl.get('_preset.master.FsItemSearch.Status')}</div>
              <Dropdown
                fluid
                clearable
                options={stateOptions || []}
                selection
                multiple
                className="preset-master-input-content multiple-select"
                onChange={(e, data) => onChange('stateFlag', data.value)}
                noResultsMessage={intl.get('_predict.admin.PdfSearch.Noresults')}
                value={searchObj.stateFlag || []} />
            </div>
            <div className="preset-master-search-condition-input">
              <div className="preset-master-input-label">{intl.get('_preset.master.FsItemSearch.New')}{intl.get('_preset.master.FsItemSearch.CreateName')}</div>
              <Dropdown
                fluid
                clearable
                options={creatorOptions}
                selection
                search
                className="preset-master-input-content"
                onChange={(e, data) => onChange('createdById', data.value)}
                noResultsMessage={intl.get('_predict.admin.PdfSearch.Noresults')}
                value={searchObj.createdById || ""} />
            </div>
            {/* <div className="preset-master-search-condition-input">
              <div className="preset-master-input-label">{intl.get('_predict.admin.PdfSearch.Uploadtime')}</div>
              <div className="preset-master-search-datepicker-warp">
                <DatePicker
                  className="preset-master-search-datepicker"
                  format="y/MM/dd"
                  calendarIcon={<CalenarIcon />}
                  clearIcon={<CancelNoCircleIcon />}
                  value={searchObj.startDate}
                  onChange={time => setSearchObj({ ...searchObj, 'startDate': time })}
                />
                <div>
                  -
                </div>
                <DatePicker
                  className="preset-master-search-datepicker"
                  calendarIcon={<CalenarIcon />}
                  clearIcon={<CancelNoCircleIcon />}
                  value={searchObj.endDate}
                  onChange={time => setSearchObj({ ...searchObj, 'endDate': time })}
                />
              </div>
            </div> */}
            <div className="preset-master-search-condition-input btngroup">
              <Button id="ga-predict-list-condition-clear" className="preset-master-botton-text" onClick={handleReset}>
                {"条件クリア"}
              </Button>
              <Button
                className="primary-btn"
                onClick={() => handleClickSearchButton()}>
                {intl.get('_predict.admin.PdfSearch.Search')}
              </Button>
            </div>
            <div className="preset-master-search-condition-input btngroup"/>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
