import React from 'react'
import { MDBDataTable } from 'mdbreact'
import { Button, Icon, Segment } from 'semantic-ui-react'
import { confirmAlert } from 'react-confirm-alert';
import RoleEdit from './Edit'
import api from '../../api'
import intl from 'react-intl-universal';

const roleName = {
  project: 'プロジェクト管理',
  label: 'ラベリング',
  demo: 'demo',
  predict: 'ワークスペース',
  user: 'ユーザー管理',
  monitor: 'モニタリング',
  train: 'AI学習',
  system: 'システム管理',
  easyocr: 'EasyOcr',
  easyocr_user: 'easyocr_user',
  tenant_owner: 'テナント管理',
  preset_master: '自動連携先更新'
}

export default class Role extends React.Component {
  state = {
    isLoaded: true,
    rows: [],
    isShowEdit: false,
    editRole: {},
    permissions: []
  }

  constructor(props) {
    super(props)
    this.handleDel = this.handleDel.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.toggleEdit = this.toggleEdit.bind(this)
    this.getRoles = this.getRoles.bind(this)
  }

  async componentDidMount() {
    await this.getRoles()
  }

  async getRoles() {
    const { data: roles } = await api.get('/dppapi/role')
    const { data: permission } = await api.get('/dppapi/permission')
    let rows = []

    if (roles) {
      rows = roles.map( (role, index) => {
        return Object.assign({}, role, {
          num: role.id,
          permission: role.permission.split(',').map(p=>parseInt(p)),
          permission_names: permission.filter(p => {
            return role.permission.split(',').map(p=>parseInt(p)).includes(p.id)
          }).reduce((ac, e) => {
            return e.name !== "easyocr_user" && e.name !== "monitor" ? `${ac ? ac + ', ' : ''}${roleName[e.name]}` : `${ac ? ac  : ''}`
          }, ''),
          operation: (
          <div>
            <Button icon="pencil" label={intl.get('_user.role.Role.Edit')} size="tiny" onClick={() => this.handleEdit(role)}/>
            <Button
              icon="trash"
              label={intl.get('_user.role.Role.Delete')}
              size="tiny"
              onClick={() => this.handleDel(role)}
            />
          </div>
          )
        })
      })
      this.setState({
        rows: rows,
        permissions: permission
      })
    }
  }

  toggleEdit(isShowEdit) {
    this.setState({
      editRole: {},
      isShowEdit: isShowEdit
    })
  }

  handleEdit(role) {
    this.setState({
      isShowEdit: true,
      editRole: this.state.rows.find(row => row.id === role.id) || {}
    })
  }

  async handleDel(role) {
    confirmAlert({
      title: intl.get('_user.role.Role.Delete this role'),
      message: intl.get('_user.role.Role.Are you sure to do this?'),
      buttons: [
        {
          className: 'user-botton-text confirmAlert-button-left',
          label: intl.get('_user.role.Role.Cancel'),
        },
        {
          className: 'primary-btn confirmAlert-button-right',
          label: intl.get('_common.Delete'),
          onClick: async () => {
            await api.delete(`/dppapi/role/${role.id}`)
            this.getRoles()
          },
        },
      ],
    })
  }

  render() {
    const  { rows, permissions, editRole, isShowEdit } = this.state;
    const columns = [
      {
        label: intl.get('_user.role.Role.Num'),
        field: 'num',
        sort: 'asc',
        width: 100
      },
      {
        label: intl.get('_user.role.Role.Name'),
        field: 'name',
        sort: 'asc',
        width: 100
      },
      {
        label: intl.get('_user.role.Role.Permission'),
        field: 'permission_names',
        sort: 'asc',
        width: 200
      },
      {
        field: 'operation',
        sort: 'disabled',
        width: 400
      }
    ]
    const data = {
      columns: columns,
      rows: rows
    }
    return (<Segment>
       <Button.Group floated='right'>
        <Button icon onClick={() => this.handleEdit({})}>
          <Icon name='add' />
        </Button>
        </Button.Group>
      <MDBDataTable
        theadColor="common-search-table-header"
        btn
        striped
        bordered
        entriesLabel='表示件数'
        hover
        data={data}
        infoLabel={["", "-", "/", "件"]} 
        paginationLabel={["前へ", "次へ"]}
      />
      { isShowEdit ? <RoleEdit isShowEdit={isShowEdit} role={ editRole } toggleEdit={this.toggleEdit} getRoles={this.getRoles} permissions={permissions}/>
: null}
    </Segment>)
  }
}