import React from 'react';
import "./Detail.css";
import { MDBModal, MDBModalBody } from 'mdbreact';

export default function Detail({isShowEdit, toggleEdit}) {

  return (
    <MDBModal isOpen={isShowEdit} toggle={toggleEdit} className="prese-master-detail-modal-container">
       <MDBModalBody>eeeeee</MDBModalBody>
    </MDBModal>
  );
}
