export const categoryOptions = [
  { key: 0, value: "貸借対照表", text: "貸借対照表" },
  { key: 1, value: "損益計算書", text: "損益計算書" },
  { key: 2, value: "株主資本等変動計算書", text: "株主資本等変動計算書" },
  { key: 3, value: "販売費及び一般管理費", text: "販売費及び一般管理費" },
  { key: 4, value: "製造原価明細表", text: "製造原価明細表" }
]

export const equalOptions = [
  {
    key: 1,
    value: 1,
    text: '一致',
  },
  {
    key: 0,
    value: 0,
    text: '不一致',
  },
]

export const stateOptions = [
  {
    key: 0,
    value: 0,
    text: '未対応',
  },
  {
    key: 1,
    value: 1,
    text: '更新済',
  },
  {
    key: 2,
    value: 2,
    text: '保留',
  },
  {
    key: 3,
    value: 3,
    text: '除外済',
  },
];


export const styleOptions = [
  "preset-master-table-tr-selected",
  "preset-master-table-tr-sendOk"
]

export const stateFlag = {
  0: '未対応',
  1: '更新済',
  2: '保留',
  3: '除外済'
}