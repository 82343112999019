import React, { useState, useEffect } from 'react'
import api from '../api';
import Menubar from '../common/Menubar';
import PresetMasterSearch from './PresetMasterSearch';
import PresetMasterList from './PresetMasterList';
import './PresetMasterApp.css';
import { Message } from 'semantic-ui-react';
import moment from 'moment';
import { FMT } from '../predict-beautify/TimeUtil';
import { stateFlag } from './Constant';
import { getUserName } from './util';

const initSearchObj = {
  isEqual: 0,
  stateFlag: [0, 2],
  // startDate: moment().add(-90, 'd').toDate(),
  // endDate: moment().toDate()
}

export default function PresetMasterApp() {
  const [initRows, setInitRows] = useState([]);
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [canSelectedRows, setCanSelectedRows] = useState([]);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [menuBoxBottomHeight, setMenuBoxBottomHeight] = useState(0);
  const [userList, setUserList] = useState([]);
  const [searchObj, setSearchObj] = useState(initSearchObj);
  const [entries, setEntries] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [checkedAll, setCheckedAll] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [text, setText] = useState("");

  const searchHeight = document.body.clientHeight - 45 - (initRows.length > 0 ? 52 : 0) - 16 - menuBoxBottomHeight - ((totalCount > 3000) ? 36 : 0);
  
  useEffect(() => {
    getFsList();
  }, [])

  const handleDependentCount = (list) => {
    const rowMap = new Map();

    for (let data of list) {
      const { category, subTitle, item, originalItem, scrumItem } = data;
      const key = `${category}_${subTitle}_${item}_${originalItem}_${scrumItem}`;

      if (!rowMap.has(key)) {
        rowMap.set(key, { ...data, dependentCount: 1 });
      } else {
        const item = rowMap.get(key);
        item.dependentCount += 1;
        rowMap.set(key, item);
      }
    }

    return Array.from(rowMap.values());
  }

  const handleDataCount = (list, users) => {
    const rowsWithDependentCount = handleDependentCount(list);
    const map = {};
    for (let data of rowsWithDependentCount) {
      const { category, subTitle, item, originalItem } = data;
      const key = `${category}_${subTitle}_${item}_${originalItem}`;
      if (map.hasOwnProperty(key)) {
        map[key] = map[key] + 1;
      } else {
        map[key] = 0;
      }
    }
    const resultList = rowsWithDependentCount.map(item => {
      const key = `${item.category}_${item.subTitle}_${item.item}_${item.originalItem}`;
      return ({
        ...item,
        count: map[key],
        stateText: stateFlag[item.stateFlag],
        updateTimeText: item.updateTime ? moment(item.updateTime).format(FMT.DATETIME_LOCAL).trim() : '',
        updateByNameText: getUserName(users, item.updateById, item.updateByName)
      });
    })
    return resultList;
  }

  //データを取得する
  const getFsList = async () => {
    setIsLoading(true);
    const params = {
      ...searchObj,
      subTitle: searchObj.subTitle ? searchObj.subTitle.trim() : undefined,
      item: searchObj.item ? searchObj.item.trim() : undefined,
      // startDate: searchObj.startDate ? toUnixMS(moment(searchObj.startDate).format(FMT.DATE)) : '',
      // endDate: searchObj.endDate ? toUnixMS(moment(searchObj.endDate).add(1, 'd').format(FMT.DATE)) : toUnixMS(moment(moment().toDate()).add(1, 'd').format(FMT.DATE)),
    }
    try {
      const { data: { fsList, totalCount } } = await api.get(`/dppapi/preset/list`, { params: { searchParams: JSON.stringify(params) } });
      const { data: { users } } = await api.get(`/dppapi/preset/user/query`);
      const list = handleDataCount(fsList, users);
      setInitRows(list);
      setRows(list);
      setUserList(users);
      setTotalCount(Number(totalCount));
      setCanSelectedRows(list.filter(item => item.stateFlag === 0 || item.stateFlag === 2));
      handleReset();
    } catch (error) {
      setError(error.data.errmsg);
    } finally {
      setIsLoading(false);
    }
  }

  const handleReset = () => {
    setEntries(10);
    setActivePage(1);
    setCheckedAll(false);
    setSelectedIds([]);
    setText("");
  }

  return (
    <Menubar active="preset_master">
      <div className="preset-master-container" style={{ maxHeight: searchHeight + 'px' }}>
        {!!error && <Message negative className="preset-master-message">{error}</Message>}
        <PresetMasterSearch
          userList={userList}
          searchObj={searchObj}
          setSearchObj={setSearchObj}
          onFetch={getFsList}
          initSearchObj={initSearchObj}
        />
        <PresetMasterList
          rows={rows}
          setRows={setRows}
          canSelectedRows={canSelectedRows}
          isLoading={isLoading}
          onUpdate={getFsList}
          setMenuBoxBottomHeight={setMenuBoxBottomHeight}
          userList={userList}
          entries={entries}
          setEntries={setEntries}
          activePage={activePage}
          setActivePage={setActivePage}
          checkedAll={checkedAll}
          setCheckedAll={setCheckedAll}
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
          initRows={initRows}
          totalCount={totalCount}
          text={text}
          setText={setText}
        />
      </div>
    </Menubar>
  )
}

