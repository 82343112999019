import React, { useState, useEffect, useRef } from 'react';
import { Loader, Dropdown, Message, Popup, Checkbox, Pagination, Dimmer, Button } from 'semantic-ui-react'
import { MDBDataTable } from 'mdbreact';
import { MoreVertIcon, MdiChevronLeftIcon, MdiChevronRightIcon, KeyboardDoubleArrowLeftIcon, KeyboardDoubleArrowRightIcon } from '../assets/icon';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import moment from "moment";
import { FMT } from '../predict/TimeUtil';
import { ReactComponent as ReactOkLogo } from '../assets/icon/svg/mdi-check_circle.svg';
import { ReactComponent as ReactDisableLogo } from '../assets/icon/svg/block_disable.svg';
import TableSelectedTools from './components/TableSelectedTools';
import { styleOptions } from './Constant';
import api from '../api';
import Detail from './components/Detail';
import { ReactComponent as ReactLogo } from '../assets/icon/svg/mdi-error-2.svg';
import { getUserName } from './util';

export default function PresetMasterList({
  rows,
  setRows,
  canSelectedRows,
  isLoading,
  onUpdate,
  setMenuBoxBottomHeight,
  userList,
  entries,
  setEntries,
  activePage,
  setActivePage,
  checkedAll,
  setCheckedAll,
  selectedIds,
  setSelectedIds,
  initRows,
  totalCount,
  text,
  setText
}) {
  const [rowsPageList, setRowsPageList] = useState([]);
  const [rowsPage, setRowsPage] = useState({ rowsPageStart: 0, rowsPageEnd: 0, rowsLength: 0 });
  const [isShowEdit, setIsShowEdit] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const [editId, setEditId] = useState();
  const [keepHover, setKeepHover] = useState(false);
  const [editValue, setEditValue] = useState({});

  let clickCount = 0;
  let singleClickTimer = '';

  const [columns, setColumns] = useState([]);
  const [sortValue, setSortValue] = useState({});
  const tableRef = useRef(null);
  const TextAreaRef = useRef(null);

  useEffect(() => {
    if (rows.length > 0) {
      handleTurnPage(rows);
    }
    //set menuBoxBottomHeight
    if (selectedIds.length > 0) {
      setMenuBoxBottomHeight(78);
    } else {
      setMenuBoxBottomHeight(0);
    }
  }, [rows, canSelectedRows, entries, selectedIds, editId, editValue, keepHover])

  useEffect(() => {
    if (tableRef.current) {
      setTimeout(() => {
        const elms = tableRef.current.querySelector(".preset-master-search-table-body").querySelectorAll('tr');
        const rows = rowsPageList[activePage - 1];

        if (!rows || (elms.length !== rows.length)) {
          return;
        }
        for (let i = 0; i < rows.length; i++) {
          if (styleOptions.includes(rows[i].rowClassName)) {
            elms[i].classList.add(rows[i].rowClassName);
          } else {
            elms[i].classList.remove(styleOptions[0]);
            elms[i].classList.remove(styleOptions[1]);
          }
        }
      }, 10)
    }
  }, [activePage, rowsPageList])

  //チャンクデータ
  const handleTurnPage = (rows) => {
    const changedRows = rows.map((row) => handleRows(row));
    const chunks = [];
    for (let i = 0; i < changedRows.length; i += entries) {
      const list = changedRows.slice(i, i + entries).map((item) => ({ ...item }));
      chunks.push(list);
    }
    setRowsPageList(chunks);
    setColumns(getColumns(sortValue.column, sortValue.dir));
    //対応するページ番号が見つからないことを避ける
    if (chunks.length < activePage) {
      setActivePage(1);
    }
  }

  useEffect(() => {
    handlePageSize();
  }, [rows, rowsPageList, entries, activePage])

  //データ長と間隔を取得する
  const handlePageSize = () => {
    if (rowsPageList.length === 0) return;
    const rowsPageStart = entries * (activePage - 1) + 1;
    const rowsPageEnd = entries * (activePage - 1) + rowsPageList[activePage - 1].length;
    setRowsPage({
      rowsPageStart: rowsPageStart || 0,
      rowsPageEnd: rowsPageEnd || 0,
      rowsLength: rows.length || 0
    })
  }

  //すべての操作を選択
  const handleSelectedAll = (checked) => {
    setCheckedAll(checked);
    if (checked) {
      const ids = canSelectedRows.map(item => item.id);
      setSelectedIds(ids);
    } else {
      setSelectedIds([]);
    }
  }

  //無線選択操作
  const handleSelected = (rowId) => {
    let ids = selectedIds;
    setSelectedIds(prev => {
      if (prev.includes(rowId)) {
        ids = prev.filter(id => id !== rowId);
        return prev.filter(id => id !== rowId);
      }
      ids = [...prev, rowId];
      return [...prev, rowId];
    })

    setCheckedAll(prev => ids.length === canSelectedRows.length);
  }

  const handleEdit = (row) => {
    setIsShowEdit(!isShowEdit);
    setCurrentRow(row);
  }

  const handleRows = (row) => {
    return Object.assign({}, row, {
      key: row.id,
      checkbox: renderCheckBox(row),
      scrumMap: renderScrumItem(row),
      updateTime: renderUpdateTime(row.updateTime),
      stateFlag: renderStatus(row.stateFlag),
      updateByName: renderUpdateName(row.updateById, row.updateByName),
      remark: renderRemark(row),
      dependentCount: renderDependeCount(row),
      action: (
        [0, 2].includes(row.stateFlag) ? (
          <Dropdown item direction='left'
            id={`predict-drop-down-${row.id}`}
            icon={<MoreVertIcon size='24px' color='#545454' />}
            className='predict-dropDown predict-dropDown-search-size'
          >
            <Dropdown.Menu id={`predict-drop-down-menu-${row.id}`}>
              <Dropdown.Item >
                <span className='text'>&nbsp;更新適用</span>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleEdit(row)}>
                <span className='text'>&nbsp;対応状況変更 </span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ) : null
      ),
      rowClassName: row.scrumItem === row.originalItem ? "preset-master-table-tr-sendOk" : selectedIds.includes(row.id) ? "preset-master-table-tr-selected" : "preset-master-table-tr-default"
    })
  }

  const renderCheckBox = (row) => {
    return (
      <div className="preset-master-td-checkbox">
        {[0, 2].includes(row.stateFlag) && (<Checkbox key={row.id} id={row.id} checked={selectedIds.includes(row.id)} onChange={() => handleSelected(row.id)} />)}
        {row.stateFlag === 1 && (
          <Popup
            className="preset-master-table-body-popup"
            trigger={<ReactOkLogo />}
            content="更新済（選択不可）"
            position="top left"
            inverted
          />
        )}
        {row.stateFlag === 3 && (
          <Popup
            className="preset-master-table-body-popup"
            trigger={<ReactDisableLogo />}
            content="除外済（選択不可）"
            position="top left"
            inverted
          />
        )}
      </div>
    )
  }

  const renderScrumItem = (row) => {
    return (
      <div className="preset-master-td-text">
        <div className="-now">
          <div>現在</div>
          <div style={{ color: row.originalItem ? '#212121' : "#AAAAAA" }}>{row.originalItem ? row.originalItem : '(未連携)'}</div>
        </div>
        <div className="-new">
          <div>更新</div>
          <div>{row.scrumItem ? row.scrumItem : '(未連携)'}</div>
        </div>
      </div>
    )
  }

  const renderUpdateTime = (time) => {
    if (!time) return "";
    const updateTime = moment(time).format(FMT.DATETIME_LOCAL).trim();
    const hour = updateTime.split(" ")[0];
    const minute = updateTime.split(" ")[1];
    return (
      <div className="preset-master-td-text-time">
        <div>{hour}</div>
        <div>{minute}</div>
      </div>
    )
  }

  const renderUpdateName = (id, name) => {
    return (
      <div className="preset-master-td-text-time">
        <div>{getUserName(userList, id, name)}</div>
      </div>
    )
  }

  const handleSaveData = async (r) => {
    if(editValue[r.id] && editValue[r.id] !== r.remark) {
      const { data } = await api.post(`/dppapi/preset/remark/update/${r.id}`, {
        remark: editValue[r.id]|| r.remark 
      });
      if (data.success) {
        onUpdate();
        setEditId(0);
      }
    } else {
      setEditId(0);
    }
  }

  const handleChange = (e, r) => {
    let editValueTemp = {...editValue, [r.id]:e.target.value}
    setEditValue(editValueTemp);
  }

  const handleClicks = (rowId) => {
    clickCount++
    if (clickCount === 1) {
      singleClickTimer = setTimeout(function () {
        clickCount = 0;
      }, 300);
    } else if (clickCount === 2) {
      clearTimeout(singleClickTimer);
      clickCount = 0;
      setKeepHover(false);
      setEditId(rowId);
      setTimeout(function () {
        if(TextAreaRef.current){
          TextAreaRef.current.focus();
          TextAreaRef.current.select();
        }
      }, 100);
    }
  }

  const renderRemark = (row) => {
    return (
      <div>
        <Popup
          className="preset-master-table-body-popup"
          trigger={
            <div>
              {row.id === editId ? (
                <textarea
                  className={'edit-remark-text'}
                  onChange={(e) => {
                    handleChange(e, row);
                  }}
                  ref={TextAreaRef}
                  value={editValue[row.id] || row.remark || ''}
                  onKeyDown={event => {
                    if (event.keyCode === 13) {
                      event.stopPropagation();
                      event.preventDefault();
                      handleSaveData(row);
                    }
                  }}
                  onBlur={() => {
                    handleSaveData(row);
                  }}
                />
              ) : (
                <div
                  className={`edit-remark
                    ${keepHover ? 'edit-remark-div-border' : ''}`
                  }
                  onMouseEnter={() => { setKeepHover(true) }}
                  onMouseLeave={() => { setKeepHover(false) }}
                  onClick={() => handleClicks(row.id)}>{row.remark || ''}</div>
              )}
            </div>
          }
          content="ダブルクリックで編集"
          position="top center"
          inverted
        />
      </div>
    )
  }

  const renderDependeCount = (row) => {
    return (
      <div>
        <div>{row.dependentCount} 件</div>
        {row.count > 0 && <div className="preset-master-td-text-competition">競合 {row.count}</div>}
      </div>
    )
  }

  const renderStatus = (state) => {
    const map = {
      0: '未対応',
      1: '更新済',
      2: '保留',
      3: '除外済'
    }
    return (
      <div className={`preset-master-td-text-state td-text-state-${state}`}>
        {map[state]}
      </div>
    )
  }

  function getSortDirection(column, sortColumn, sortDirection) {
    return !sortColumn ? "" : (sortColumn === column) ? sortDirection : ""
  }

  const handleSort = (value) => {
    const { column, direction } = value;
    if (!column) return;

    let list = [];
    if (["id", "dependentCount", "stateFlag", "updateTime"].includes(column)) {
      list = rows.sort((a, b) => direction === 'asc' ? a[column] - b[column] : b[column] - a[column]);
    } else if (column === "updateByName") {
      list = rows.sort((a, b) => direction === 'asc' ? a.updateById - b.updateById : b.updateById - a.updateById);
    } else {
      list = rows.sort((a, b) => direction === 'asc' ? a[column].localeCompare(b[column]) : b[column].localeCompare(a[column]));
    }
    handleTurnPage(list);

    const dir = direction === 'asc' ? 'desc' : "asc";
    const columns = getColumns(column, dir);
    setColumns(columns);
    setSortValue({ column: column, direction: dir });
  }

  const handleSearch = (e) => {
    e.preventDefault();
    setText(e.target.value);
    let value = e.target.value.trim();

    if (!value) {
      setRows(initRows);
      return;
    }

    const searchList = initRows.filter(row => {
      return (row.id && row.id === Number(value)) ||
        (row.category && row.category.indexOf(value) !== -1) ||
        (row.subTitle && row.subTitle.indexOf(value) !== -1) ||
        (row.item && row.item.indexOf(value) !== -1) ||
        (row.scrumItem && row.scrumItem.indexOf(value) !== -1) ||
        (row.originalItem && row.originalItem.indexOf(value) !== -1) ||
        (row.remark && row.remark.indexOf(value) !== -1) ||
        (row.stateText && row.stateText.indexOf(value) !== -1) ||
        (row.updateTimeText && row.updateTimeText.indexOf(value) !== -1) ||
        (row.updateByNameText && row.updateByNameText.indexOf(value) !== -1)
    })
    handleTurnPage(searchList);
    setRows(searchList);
  }

  function getColumns(column, dir) {
    const columns = [
      {
        label: (
          <>
            <Popup
              trigger={<Checkbox id="all" checked={checkedAll} indeterminate={!checkedAll && selectedIds.length > 0} onChange={(e, { checked }) => handleSelectedAll(checked)} />}
              position="top left"
              content={'表示中のファイルを一括選択/選択解除'}
              on="hover"
              wide='very'
              hideOnScroll
              inverted
            />
          </>
        ),
        field: 'checkbox',
        sort: 'disabled',
      },
      {
        label: <div>No.</div>,
        field: 'id',
        sort: getSortDirection('id', column, dir)
      },
      {
        label: '分類',
        field: 'category',
        sort: getSortDirection('category', column, dir)
      },
      {
        label: '読取区分',
        field: 'subTitle',
        sort: getSortDirection('subTitle', column, dir)
      },
      {
        label: '読取科目',
        field: 'item',
        sort: getSortDirection('item', column, dir)
      },
      {
        label: '自動連携先',
        field: 'scrumMap',
        sort: 'disabled',
      },
      {
        label: '更新依頼',
        field: 'dependentCount',
        sort: getSortDirection('dependentCount', column, dir)
      },
      {
        label: '対応状況',
        field: 'stateFlag',
        sort: getSortDirection('stateFlag', column, dir)
      },
      {
        label: '備考',
        field: 'remark',
        sort: 'disabled',
      },
      {
        label: '対応日時',
        field: 'updateTime',
        sort: getSortDirection('updateTime', column, dir)
      },
      {
        label: '対応者',
        field: 'updateByName',
        sort: getSortDirection('updateByName', column, dir)
      },
      {
        label: '',
        field: 'action',
        sort: 'disabled',
      },
    ];
    return columns;
  }

  return (
    <div style={{ flex: 1, font: 'normal normal bold 12px/18px Noto Sans JP' }}>
      {isLoading && <Dimmer active inverted>
        <Loader active inline="centered" />
      </Dimmer>}
      {initRows.length > 0 && (
        <div id='preset-master-table' className="preset-master-search-table-panel" ref={tableRef}>
          <div className='row dataTables_wrapper'>
            <div data-test="" className="">
              <div data-test="" className="dataTables_filter">
                <label className={selectedIds.length > 0 ? "serach-label" : ""}>絞り込み
                  <input type="search" value={text} disabled={selectedIds.length > 0} className="form-control form-control-sm" placeholder="キーワード" onChange={handleSearch} />
                </label>
              </div>
            </div>
            <div data-test="" className="">
              <div className="dataTables_length bs-select">
                <label>表示件数
                  <select className="custom-select custom-select-sm form-control form-control-sm" style={{ marginLeft: '7px' }} key={entries} defaultValue={entries} onChange={(e) => setEntries(e.target.value)}>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </label>
              </div>
            </div>
          </div>
          <MDBDataTable
            theadColor="preset-master-search-table-header"
            tbodyColor="preset-master-search-table-body"
            borderless
            small
            data={{ columns: columns, rows: rowsPageList[activePage - 1] }}
            searching={false}
            paging={false}
            className="table-col-sm-12 "
            noRecordsFoundLabel="該当データがありません。条件を変更の上、再度検索してください。"
            onSort={e => handleSort(e)}
          />
          {initRows.length > 0 && (
            <div className="preset-master-datatable-info-container">
              <div className="preset-master-datatable-info-pagination">
                <div className="preset-master-datatable-info-style">
                  {rows.length === 0 ? <div role="status" aria-live="polite">0 件</div> :
                    <div role="status" aria-live="polite"> {rowsPage.rowsPageStart} - {rowsPage.rowsPageEnd} / {rowsPage.rowsLength} 件</div>
                  }
                  <Pagination
                    size="mini"
                    className="preset-master-datatable-pagination-style"
                    secondary
                    activePage={activePage}
                    totalPages={rowsPageList.length || 1}
                    onPageChange={(e, data) => setActivePage(data.activePage)}
                    boundaryRange={0}
                    siblingRange={4}
                    ellipsisItem={null}
                    firstItem={{ content: <KeyboardDoubleArrowLeftIcon color={'#545454'} size='24px' />, icon: true }}
                    lastItem={{ content: <KeyboardDoubleArrowRightIcon color={'#545454'} size='24px' />, icon: true }}
                    prevItem={{ content: <MdiChevronLeftIcon color={'#545454'} size='24px' />, icon: true }}
                    nextItem={{ content: <MdiChevronRightIcon color={'#545454'} size='24px' />, icon: true }}
                  />
                </div>
                <Button className="preset-master-new-create-btn"><span>+</span> <span>新規作成</span></Button>
              </div>
              {selectedIds.length > 0 && (
                <TableSelectedTools
                  selectedIds={selectedIds}
                  canSelectedData={canSelectedRows}
                  handleSelectedAll={handleSelectedAll}
                />
              )}
              {totalCount > 3000 && <div className="preset-master-more-count-tip">
                <ReactLogo style={{ verticalAlign: '-7px', height: '24px', marginRight: '8px' }} />
                表示可能アイテム数の上限（3000件）を超過したため、最新の3000件のみ表示しています
              </div>
              }
            </div>)}
        </div>
      )}
      {initRows.length === 0 && !isLoading && (
        <div id='preset-master-table' className="preset-master-search-table-panel">
          <Message
            className="noDataMessage"
            info
            header='該当データがありません。 '
            content="条件を変更の上、再度検索してください。"
          />
        </div>
      )}
      <Detail
        isShowEdit={isShowEdit}
        toggleEdit={() => setIsShowEdit(false)}
        item={currentRow}
        onUpdate={onUpdate}
      />
    </div>
  )
}