import React from 'react';
import { Button } from 'semantic-ui-react';
import Slide from '@material-ui/core/Slide';
import { Link } from 'react-router-dom';

export default function TableSelectedTools({ selectedIds, canSelectedData, handleSelectedAll }) {

  return (
    <div>
      <Slide direction="up" in={selectedIds.length > 0}>
        <div id="list-action-menu" className="preset-master-list-action-menu">
          <div className="preset-master-list-action-menu-label">{selectedIds.length} 件
            <span className="preset-master-list-action-menu-label-detail">選択中</span>
            <Button
              className="primary-btn"
              style={{ marginRight: "8px" }}
            >
              更新適用
            </Button>
            <Button
              id="ga-predict-list-delete"
              size="medium"
              onClick={() => {
              }}
              className="preset-master-botton-text preset-master-userOption">
              対応状況変更
            </Button>
          </div>
          <div className="preset-master-list-action-menu-link">
            <Link to="#" onClick={() => handleSelectedAll(true)}>
              <u className="preset-master-list-action-menu-link-u">{canSelectedData.length} 件 すべて選択</u>
            </Link>
            <Link to="#" onClick={() => handleSelectedAll(false)}>
              <u className="preset-master-list-action-menu-link-u">選択解除</u>
            </Link>
          </div>
        </div>
      </Slide>
    </div>
  );
}
